<template>

  <div ref="baseBtn" @mouseover="handleHover" @mouseleave="handleLeave" class="base-btn" :style="buttonStyles">
    <LocLink v-if="checkPath === true" class="button-link" :to="$rt(Props.url)" :style="getLinkStyle">
      {{ $rt(Props.label).toUpperCase() }}
    </LocLink>
    <nuxt-link v-else class="button-link" :to="$rt(Props.url)" :style="getLinkStyle">{{ $rt(Props.label).toUpperCase() }}</nuxt-link>
  </div>

</template>
<script>
import { gsap } from 'gsap';
import LocLink from './LocLink.vue';
export default {
  data () {
    return {
      nelsonUrl: 'https://nelsonirrigation.com'
    };
  },
  props: [
    'label',
    'url',
    'buttonStyle',
  ],
  components: {
    LocLink
  },
  computed:{
    Props (){
      return this.$props;
    },
    buttonStyles () {

      let style = {
        'background-color': this.$props.buttonStyle.color,

      };
      return style;
    },
    getLinkStyle () {
      return {
        'fontSize' : this.$props.buttonStyle.fontSize ? this.$props.buttonStyle.fontSize  : '',
        'textTransform' : 'none',
      };
    },
    checkPath () {
      const url = this.$props.url;
      const firstChar = Array.from(url)[0];
      if (firstChar === '/') {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {

     handleHover () {
      let el = this.$refs.baseBtn;
      gsap.to(el, {
          backgroundColor: this.$props.buttonStyle.hover,
          duration: .4
      });
     },
     handleLeave () {
      let el = this.$refs.baseBtn;
      gsap.to(el, {
          backgroundColor: this.$props.buttonStyle.color,
          duration: .4
      });
     }
  }
};
</script>
<style scoped>

.base-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 240px;
  padding: 16px;
  line-height: 1.2;
}

@media only screen and (max-width: 900px) {
  .base-btn {
    font-size: 10px;
    padding: 23px;
  }
}

.button-link {
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-decoration: underline;
  color: white;
}
</style>